import { constants } from "./constants";
import Cookie from "js-cookie";
export default (function (url, method, body, dispatch) {
  var headers = new Headers({
    "Accept": "application/json",
    "Content-Type": "application/json"
  });

  if (method === 'POST' || method === 'DELETE' || method === 'PUT') {
    headers.append('X-XSRF-TOKEN', Cookie.get('XSRF-TOKEN'));
  }

  var jsonBody = body ? JSON.stringify(body) : undefined;
  var init = {
    credentials: 'same-origin',
    headers: headers,
    method: method,
    body: jsonBody
  };
  return fetch(url, init).then(function (response) {
    if (!response.ok) {
      throw response;
    }

    var expires = response.headers.get(constants.tokenExpiresHeader);

    if (expires) {
      dispatch({
        type: constants.actions.UPDATE_EXPIRES,
        expires: expires
      });
    }

    return response.json();
  });
});
export var fetchResponse = function fetchResponse(url, method, body, dispatch) {
  var headers = new Headers({
    "Accept": "application/json",
    "Content-Type": "application/json"
  });

  if (method === 'POST' || method === 'DELETE' || method === 'PUT') {
    headers.append('X-XSRF-TOKEN', Cookie.get('XSRF-TOKEN'));
  }

  var jsonBody = body ? JSON.stringify(body) : undefined;
  var init = {
    credentials: 'same-origin',
    headers: headers,
    method: method,
    body: jsonBody
  };
  return fetch(url, init).then(function (response) {
    if (!response.ok) {
      throw response;
    }

    var expires = response.headers.get(constants.tokenExpiresHeader);

    if (expires) {
      dispatch({
        type: constants.actions.UPDATE_EXPIRES,
        expires: expires
      });
    }

    return response;
  });
};
export var fetchJsonWithoutDispatch = function fetchJsonWithoutDispatch(url) {
  var headers = new Headers({
    "Accept": "application/json",
    "Content-Type": "application/json"
  });
  var init = {
    credentials: 'same-origin',
    headers: headers,
    method: "GET"
  };
  return fetch(url, init).then(function (response) {
    if (!response.ok) {
      throw response;
    }

    return response.json();
  });
};
export var fetchCSV = function fetchCSV(url, dispatch) {
  var headers = new Headers({
    "Accept": "text/csv",
    "Content-Type": "application/json"
  });
  var init = {
    credentials: 'same-origin',
    headers: headers,
    method: "GET"
  };
  return fetch(url, init).then(function (response) {
    if (!response.ok) {
      throw response;
    }

    var expires = response.headers.get(constants.tokenExpiresHeader);

    if (expires) {
      dispatch({
        type: constants.actions.UPDATE_EXPIRES,
        expires: expires
      });
    }

    return response;
  });
};
export var fetchCSVPost = function fetchCSVPost(url, body, dispatch) {
  var headers = new Headers({
    "Accept": "text/csv",
    "Content-Type": "application/json",
    'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN')
  });
  var jsonBody = body ? JSON.stringify(body) : undefined;
  var init = {
    credentials: 'same-origin',
    headers: headers,
    method: "POST",
    body: jsonBody
  };
  return fetch(url, init).then(function (response) {
    if (!response.ok) {
      throw response;
    }

    var expires = response.headers.get(constants.tokenExpiresHeader);

    if (expires) {
      dispatch({
        type: constants.actions.UPDATE_EXPIRES,
        expires: expires
      });
    }

    return response;
  });
};